<template>
  <v-app-bar
    absolute
    app
    flat
    color="#f9f9f9"
    height="75"
    class="app-bar-border"
  >
    <img
      src="../../assets/logo.png"
      height="60px"
      class="ml-n5"
    >
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />
    <div class="mr-5">
      <span class="body-1">
        {{user.name}}
      </span>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['user']),
    ...mapState(['drawer'])
  },

  watch: {
    $route (to, from) {
      this.showPopsCount = to.fullPath.localeCompare('/dashboard') !== 0
    }
  },

  methods: {
    setDrawer () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
  .app-bar-border {
    border-bottom: 1px solid rgb(214, 214, 214) !important;
  }
</style>
